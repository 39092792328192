<!--  -->
<template>
  <el-dialog
    title="选择银行"
    class="dialog_content"
    :visible="showDialog"
    @open="handleAction"
    @close="$emit('update:showDialog', false)">
      <el-form inline>
        <el-form-item label="银行名称">
          <el-input size="small" v-model="search.branch" placeholder="输入银行名称"></el-input>
        </el-form-item>
        <el-form-item label="城市">
          <el-input size="small" v-model="search.city" placeholder="输入城市"></el-input>
        </el-form-item>
        <el-form-item label="分行名称">
          <el-input size="small" v-model="search.bankName" placeholder="输入分行名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="query-btn" size="small" @click="submit">查询</el-button>
          <el-button size="small" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table 
        class="table_content"
        border
        stripe
        v-loading="listLoading"
        :data="bankList.list"
        :header-cell-style="{backgroundColor: 'rgb(243, 243, 243)', color: 'rgb(102, 102, 102)', height: '36px', padding: 0}"
        :row-style="{height: '36px'}"
        :cell-style="{padding: 0}">
        <el-table-column align="center" type="index" label="序号" width="60"></el-table-column>
        <el-table-column property="branch" label="银行名称" width="200"></el-table-column>
        <el-table-column property="city" label="城市" width="200"></el-table-column>
        <el-table-column property="bankName" label="分行名称"></el-table-column>
        <el-table-column align="center" label="操作" width="100">
          <template slot-scope="{ row }">
            <el-link type="primary" @click="handleSelect(row)">选择</el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        class="pagination_content"
        layout="prev, pager, next"
        :total="bankList.total"
        :page-size="10"
        :current-page="page.current"
        @current-change="handleChangePage">
      </el-pagination>
    </el-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      page: {
        size: 10,
        current: 0
      },
      search: {
        branch: '',
        city: '',
        bankName: ''
      }
    }
  },
  computed: {
    ...mapState(['listLoading']),
    ...mapGetters(['bankList'])
  },
  created() {
    this.getBankList(this.page)
  },

  methods: {
    ...mapActions(['getBankList']),
    handleChangePage (param) {
      this.page = {
        ...this.page,
        current: param
      }
      this.handleAction()
    },
    reset () {
      this.search = {
        branch: '',
        city: '',
        bankName: '',
      }
      this.submit()
    },
    submit () {
      this.page = {
        current: 0,
        size: 10
      }
      this.handleAction()
    },
    handleAction () {
      const params = {
        ...this.search,
        ...this.page,
      }
      this.getBankList(params)
    },
    handleSelect(data) {
      this.$emit('select', data)
      this.$emit('update:showDialog', false)
    }
  }
}
</script>
<style lang='scss'>
.dialog_content {
  .el-dialog {
    width: 960px !important;
  }
  .table_content {
    margin-bottom: 20px;
  }
  .pagination_content {
    text-align: right;
  }
}
.query-btn {
  color: #fff !important;
  border: none !important;
  font-size: 14px !important;
  font-weight: 400;
  background: linear-gradient(to right, #fdbd32, #e46527) !important;
}
</style>