<template>
	<div class="page-step-company-info">
		<div class="custom-headline">入驻申请</div>
		<el-card>
			<Step v-model="stepActiveIndex"></Step>
			<!-- 电子营业执照 -->
			<div class="custom-card">
				<div class="custom-title">电子营业执照</div>
				<el-form ref="companyInfoForm_license" :model="companyInfoForm" :rules="companyInfoFormRules"
					label-width="220px">
					<el-row>
						<el-col>
							<el-form-item label="营业执照照片" prop="licUploadType">
								<el-radio-group v-model="companyInfoForm.licUploadType" size="medium"
									:disabled="editable < 0" @change="handleUploadTypeChange">
									<el-radio v-for="l in licUploadTypeOptions" :key="l.value" :label="l.value">
										{{l.label}}</el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row v-if="companyInfoForm.licUploadType == '1'">
						<el-col>
							<el-form-item label="" prop="_licResId">
								<el-upload ref="licenseUpload" list-type="picture-card" :action="uploadUrl"
									:file-list="companyInfoForm._license.fileList" :accept="uploadConfig.base.accept"
									:limit="uploadConfig._license.limit" :multiple="uploadConfig._license.multiple"
									:before-upload="(file) => handleBeforeUpload(file, '_license')"
									:on-success="(res) => handleUploadSuccess(res, '_license', '_licResId')"
									:before-remove="(file) => handleFileRemove(file, '_license', '_licResId')"
									:on-preview="handlePicturePreview"
									:class="{disabled: uploadConfig._license.disabled}">
									<i class="el-icon-plus"></i>
									<div slot="tip" class="el-upload__tip">
										<div v-for="(r, index) in uploadConfig._license.remark" :key="index"
											class="custom-upload-mark">{{r}}</div>
									</div>
								</el-upload>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row v-show="companyInfoForm.licUploadType == '2'">
						<el-col>
							<el-form-item label="">
								<div class="qr-code-box">
									<div class="qr-code" ref="license"></div>
									<div class="text-box">
										<div class="text">打开微信后扫码拍摄</div>
										<div calss="text">并上传证件照片</div>
									</div>
									<el-button class="ocr-btn" type="primary" :loading="submitPendingFlag"
										@click="startOcrTimer('_license', 1)">我已上传
									</el-button>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<!-- ocr -->
					<div class="card-inner">
						<div class="label">请核对信息</div>
						<el-row>
							<el-col :span="22">
								<el-form-item label="公司名称" prop="name">
									<el-input v-model.trim="companyInfoForm.name" placeholder="请输入公司名称" maxlength="50"
										:disabled="editable < 0" clearable>
									</el-input>
									<div class="el-upload__tip custom-upload-mark">请按照营业执照上登记的完整名称填写</div>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="22">
								<el-form-item label="统一信用代码" prop="creditCode">
									<el-input v-model.trim="companyInfoForm.creditCode" placeholder="请输入统一信用代码"
										:disabled="editable < 0" maxlength="18" clearable>
									</el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="22">
								<el-form-item label="公司类型" prop="nature">
									<el-select style="width:100%" v-model="companyInfoForm.nature"
										:disabled="editable < 0" placeholder="请选择公司类型">
										<el-option v-for="c in companyNatureList" :key="c.dictValue"
											:label="c.dictLabel" :value="c.dictValue"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="22">
								<el-form-item label="营业执照所在地" prop="coRegAddr">
									<el-input v-model.trim="companyInfoForm.coRegAddr" placeholder="请输入营业执照所在地"
										:disabled="editable < 0" clearable>
									</el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row class="editable">
							<el-col :span="22">
								<el-form-item label="开票信息地址" prop="ticketAddr">
									<el-input v-model.trim="companyInfoForm.ticketAddr" placeholder="请输入开票信息地址" clearable>
									</el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="22">
								<el-form-item label="邮政编码" prop="postCode">
									<el-input v-model.trim="companyInfoForm.postCode" placeholder="请输入邮政编码" clearable
										:disabled="editable < 0">
									</el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row class="editable">
							<el-col :span="22">
								<el-form-item label="成立日期" prop="establishment">
									<el-date-picker class="date-picker" v-model="companyInfoForm.establishment"
										format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="请选择成立日期" clearable>
									</el-date-picker>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row class="editable">
							<el-col>
								<el-form-item label="营业期限" prop="licDeadlineType">
									<el-checkbox class="date-picker-limit" true-label="1" false-label="2"
										v-model="companyInfoForm.licDeadlineType">长期有效
									</el-checkbox>
								</el-form-item>
								<div class="date-range-box" v-if="companyInfoForm.licDeadlineType == '2'">
									<el-form-item class="date-item" label="" prop="licDeadlineStart">
										<el-date-picker class="date-picker" v-model="companyInfoForm.licDeadlineStart"
											format="yyyy-MM-dd" value-format="yyyy-MM-dd"
											placeholder="请选择" clearable>
										</el-date-picker>
									</el-form-item>
									至
									<el-form-item class="date-item end" label="" prop="licDeadlineEnd">
										<el-date-picker class="date-picker" v-model="companyInfoForm.licDeadlineEnd"
											format="yyyy-MM-dd" value-format="yyyy-MM-dd"
											placeholder="请选择" clearable>
										</el-date-picker>
									</el-form-item>
								</div>
							</el-col>
						</el-row>
						<el-row>
							<el-col>
								<el-form-item label="注册资本" prop="capital">
									<el-input-number class="reg-capital" v-model="companyInfoForm.capital"
										:disabled="editable < 0" controls-position="right" :min="1" :max="999999">
									</el-input-number>
									万元
								</el-form-item>
							</el-col>
						</el-row>
						<el-row class="editable">
							<el-col :span="22">
								<el-form-item label="经营范围" prop="businessScope">
									<el-input type="textarea" :autosize="{ minRows: 6, maxRows: 6}"
										placeholder="请输入经营范围" v-model="companyInfoForm.businessScope">
									</el-input>
								</el-form-item>
							</el-col>
						</el-row>
					</div>
				</el-form>
				<div class="mask" v-if="editable < 0"></div>
			</div>
			<!-- 法人信息 -->
			<div class="custom-card">
				<div class="custom-title">法人信息</div>
				<el-form ref="companyInfoForm_boss" :model="companyInfoForm" :rules="companyInfoFormRules"
					label-width="220px">
					<el-row>
						<el-col :span="14">
							<el-form-item label="证件类型" prop="legalIdType">
								<el-select style="width:100%" v-model="companyInfoForm.legalIdType" placeholder="请选择">
									<el-option v-for="b in legalIdTypeOptions" :key="b.value" :label="b.label"
										:value="b.value"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col>
							<el-form-item label="证件上传" prop="legalUploadType">
								<el-radio-group v-model="companyInfoForm.legalUploadType" size="medium"
									@change="handleUploadTypeChange">
									<el-radio v-for="p in legalUploadTypeOptions" :key="p.value" :label="p.value">
										{{p.label}}</el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row v-if="companyInfoForm.legalUploadType == '1'">
						<el-col :span="10">
							<el-form-item label="" prop="_idCardJustPath">
								<el-upload ref="bossFrontUpload" list-type="picture-card" :action="uploadUrl"
									:file-list="companyInfoForm._bossFront.fileList" :accept="uploadConfig.base.accept"
									:limit="uploadConfig._bossFront.limit" :multiple="uploadConfig._bossFront.multiple"
									:before-upload="(file) => handleBeforeUpload(file, '_bossFront')"
									:on-success="(res) => handleUploadSuccess(res, '_bossFront', '_idCardJustPath')"
									:before-remove="(file) => handleFileRemove(file, '_bossFront', '_idCardJustPath')"
									:on-preview="handlePicturePreview"
									:class="{disabled: uploadConfig._bossFront.disabled}">
									<i class="el-icon-plus"></i>
									<div slot="tip" class="el-upload__tip">
										<p v-for="(r, index) in uploadConfig._bossFront.remark" :key="index"
											class="custom-upload-mark">{{r}}</p>
									</div>
								</el-upload>
							</el-form-item>
						</el-col>
						<el-col :span="10">
							<el-form-item class="custom-form-item" label="" prop="_idCardBackPath">
								<el-upload ref="bossBackUpload" list-type="picture-card" :action="uploadUrl"
									:file-list="companyInfoForm._bossBack.fileList" :accept="uploadConfig.base.accept"
									:limit="uploadConfig._bossBack.limit" :multiple="uploadConfig._bossBack.multiple"
									:before-upload="(file) => handleBeforeUpload(file, '_bossBack')"
									:on-success="(res) => handleUploadSuccess(res, '_bossBack', '_idCardBackPath')"
									:before-remove="(file) => handleFileRemove(file, '_bossBack', '_idCardBackPath')"
									:on-preview="handlePicturePreview"
									:class="{disabled: uploadConfig._bossBack.disabled}">
									<i class="el-icon-plus"></i>
									<div slot="tip" class="el-upload__tip">
										<p v-for="(r, index) in uploadConfig._bossBack.remark" :key="index"
											class="custom-upload-mark">{{r}}</p>
									</div>
								</el-upload>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row v-show="companyInfoForm.legalUploadType == '2'">
						<el-col>
							<el-form-item label="">
								<div class="qr-code-box">
									<div class="qr-code" ref="bossId"></div>
									<div class="text-box">
										<div class="text">打开微信后扫码</div>
										<div calss="text">拍摄并上传证件照片</div>
									</div>
									<el-button class="ocr-btn" size="small" type="primary" :loading="submitPendingFlag"
										@click="() => {startOcrTimer('_bossFront', 2);startOcrTimer('_bossBack', 3)}">
										我已上传</el-button>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<!-- ocr -->
					<div class="card-inner">
						<div class="label">请核对信息</div>
						<el-row>
							<el-col :span="22">
								<el-form-item label="法人姓名" prop="legalName">
									<el-input v-model.trim="companyInfoForm.legalName" placeholder="请输入法人姓名" clearable>
									</el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="22">
								<el-form-item label="法人身份证号" prop="legalIdCode">
									<el-input v-model.trim="companyInfoForm.legalIdCode" placeholder="请输入法人身份证号"
										clearable>
									</el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row>
							<el-col>
								<el-form-item label="法人证件有效期" prop="idCardDeadlineType">
									<el-checkbox class="date-picker-limit" true-label="1" false-label="2"
										v-model="companyInfoForm.idCardDeadlineType">长期有效
									</el-checkbox>
								</el-form-item>
								<div class="date-range-box" v-if="companyInfoForm.idCardDeadlineType == '2'">
									<el-form-item class="date-item" label="" prop="idCardDeadlineStart">
										<el-date-picker class="date-picker"
											v-model="companyInfoForm.idCardDeadlineStart" format="yyyy-MM-dd"
											value-format="yyyy-MM-dd" placeholder="请选择" clearable>
										</el-date-picker>
									</el-form-item>
									至
									<el-form-item class="date-item end" label="" prop="idCardDeadlineEnd">
										<el-date-picker class="date-picker" v-model="companyInfoForm.idCardDeadlineEnd"
											format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="请选择" clearable>
										</el-date-picker>
									</el-form-item>
								</div>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="22">
								<el-form-item label="法人手机号" prop="legalPhone">
									<el-input v-model.trim="companyInfoForm.legalPhone" placeholder="请输入法人手机号"
										maxlength="11" clearable>
									</el-input>
								</el-form-item>
							</el-col>
						</el-row>
					</div>
				</el-form>
			</div>
			<!-- 税务信息 -->
			<div class="custom-card">
				<div class="custom-title">税务信息</div>
				<el-form ref="companyInfoForm_tax" :model="companyInfoForm" :rules="companyInfoFormRules"
					label-width="220px">
					<el-row>
						<el-col :span="14">
							<el-form-item label="纳税人类型" prop="taxPayerFlag">
								<el-select style="width:100%" v-model="companyInfoForm.taxPayerFlag" placeholder="请选择">
									<el-option v-for="t in taxPayerFlagOptions" :key="t.value" :label="t.label"
										:value="t.value"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row v-show="companyInfoForm.taxPayerFlag == '0'">
						<el-col>
							<el-form-item label="一般纳税人类型" prop="_taxPayerPic">
								<el-upload ref="taxUpload" list-type="picture-card" :action="uploadUrl"
									:file-list="companyInfoForm._tax.fileList" :accept="uploadConfig.base.accept"
									:limit="uploadConfig._tax.limit" :multiple="uploadConfig._tax.multiple"
									:before-upload="(file) => handleBeforeUpload(file, '_tax')"
									:on-success="(res) => handleUploadSuccess(res, '_tax', '_taxPayerPic')"
									:before-remove="(file) => handleFileRemove(file, '_tax', '_taxPayerPic')"
									:on-preview="handlePicturePreview" :class="{disabled: uploadConfig._tax.disabled}">
									<i class="el-icon-plus"></i>
									<div slot="tip" class="el-upload__tip">
										<div v-for="(r, index) in uploadConfig._tax.remark" :key="index"
											class="custom-upload-mark">
											{{r}}</div>
									</div>
								</el-upload>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
			<!-- 银行信息 -->
			<div class="custom-card">
				<div class="custom-title">银行信息</div>
				<el-form ref="companyInfoForm_bank" :model="companyInfoForm" :rules="companyInfoFormRules"
					label-width="220px">
					<el-row>
						<el-col :span="14">
							<el-form-item label="支行名称:" prop="bankDesc">
								<el-input v-model.trim="companyInfoForm.bankDesc" disabled placeholder="请输入支行名称"
									clearable>
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3" :offset="1">
							<el-button type="primary" class="btn-select" size="middle" @click="toggleSelectBankFlag">
								银行选择</el-button>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="14">
							<el-form-item label="银行账号:" prop="bankCode">
								<el-input v-model.trim="companyInfoForm.bankCode" placeholder="请输入银行账号" clearable
									:disabled="editable < 0">
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="14">
							<el-form-item label="开户行地址:" prop="bankAddr">
								<el-input v-model.trim="companyInfoForm.bankAddr" placeholder="请输入开户行地址" clearable
									:disabled="editable < 0">
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row class="editable">
						<el-col :span="14">
							<el-form-item label="银行账户持有人:" prop="bankCountMaster">
								<el-input v-model.trim="companyInfoForm.bankCountMaster" placeholder="请输入银行账户持有人" clearable>
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row class="editable">
						<el-col>
							<el-form-item label="对公对私:" prop="selfOrAll">
								<el-radio-group v-model="companyInfoForm.selfOrAll" size="medium">
									<el-radio v-for="b in selfOrAllOptions" :key="b.value" :label="b.value">{{b.label}}
									</el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<div class="mask" v-if="editable < 0"></div>
				<!-- 操作 -->
				<div class="custom-action-box">
					<el-button class="btn reset" @click="handleReset">重置</el-button>
					<el-button class="btn editable" type="primary" :loading="submitPendingFlag" @click="jump2nextStep">
						下一步
					</el-button>
				</div>
			</div>
		</el-card>

		<!-- 弹窗 - 图片预览 -->
		<el-dialog :visible.sync="uploadConfig.base.previewPictureFlag">
			<img width="100%" :src="uploadConfig.base.previewPictureSrc" />
		</el-dialog>
		<!-- 选择银行 -->
		<BankList :show-dialog.sync="selectBankFlag" @select="handleBankSelect"></BankList>
	</div>
</template>

<script>
import Step from "../components/Step";
import { getUploadFile } from "@/api";
import {
	getJoinAllFormInfo,
	sumbitOcrSrc,
	getOcrResultByType,
	submitCompanyInfoForm,
} from "@/api/api-join-mall";
import { identityCardsValidator } from "@/utils/validate";
import { JoinFrom } from "@/utils/cache";
import QRCode from "qrcodejs2";
import { mapGetters } from "vuex";
import BankList from "@/components/BankList";
export default {
	components: {
		Step,
		BankList,
	},
	computed: {
		...mapGetters(["companyNatureList"]),
		uploadUrl() {
			return `/${
				process.env.VUE_APP_MODE === "production" ? "api-match" : "api"
			}/gm-nxcloud-resource/api/nxcloud/res/upload`;
		},
	},
	data() {
		return {
			editable: 1, // 预留能否编辑标识 1 可编辑 -1 不可比那集
			stepActiveIndex: [0, 1],
			// 选择器
			licUploadTypeOptions: [
				{
					value: "1",
					label: "电脑上传",
				},
				{
					value: "2",
					label: "微信上传",
				},
			],
			legalIdTypeOptions: [
				{
					value: "1",
					label: "大陆身份证",
				},
				{
					value: "2",
					label: "护照",
				},
				{
					value: "3",
					label: "港澳居民通行证",
				},
				{
					value: "4",
					label: "台湾居民通行证",
				},
			],
			legalUploadTypeOptions: [
				{
					value: "1",
					label: "电脑上传",
				},
				{
					value: "2",
					label: "微信上传",
				},
			],
			taxPayerFlagOptions: [
				{
					value: "0",
					label: "一般纳税人",
				},
				{
					value: "1",
					label: "小规模纳税人",
				},
			],
			selfOrAllOptions: [
				{
					value: "0",
					label: "对公",
				},
				{
					value: "1",
					label: "对私",
				},
			],
			// 上传配置
			uploadConfig: {
				base: {
					accept: ".jpg,.jpeg,.png,gif",
					fileType: [
						"image/png",
						"image/jpg",
						"image/jpeg",
						"image/gif",
					],
					previewPictureFlag: false,
					previewPictureSrc: "",
				},
				_license: {
					limit: 1,
					multiple: true,
					maxSize: 5,
					disabled: false,
					remark: [
						"图片尺寸请确保800px*800px以上，文件大小在5MB以内，支持png、jpeg、gif格式",
						"请上传清晰营业执照图片，系统识别公司信息自动进行填写，营业执照复印件需加盖公司红章扫描上传",
					],
				},
				_bossFront: {
					limit: 1,
					multiple: false,
					maxSize: 5,
					disabled: false,
					remark: ["上传人像面"],
				},
				_bossBack: {
					limit: 1,
					multiple: false,
					maxSize: 5,
					disabled: false,
					remark: ["上传国徽面"],
				},
				_tax: {
					limit: 1,
					multiple: false,
					maxSize: 5,
					disabled: false,
					remark: [
						"文件大小在5M以内，支持png、jpeg、gif格式",
						"若为复印件，需加盖公司红章后上传",
					],
				},
			},
			// 中间变量
			companyInfoFormList: ["license", "boss", "tax", "bank"],
			// 二维码
			licenseQrCode: null,
			bossQrCode: null,
			// 表单
			companyInfoFormPicKeyList: [
				{
					ref: "licenseUpload",
					middleKey: "_license",
					key: "licResId",
					type: 1,
				},
				{
					ref: "bossFrontUpload",
					middleKey: "_bossFront",
					key: "idCardJustPath",
					type: 2,
				},
				{
					ref: "bossBackUpload",
					middleKey: "_bossBack",
					key: "idCardBackPath",
					type: 3,
				},
				{
					ref: "taxUpload",
					middleKey: "_tax",
					key: "taxPayerPic",
				},
			],
			companyInfoForm: {
				// 营业执照
				_license: {
					fileList: [],
				},
				licUploadType: "1", // 1-电脑上传，2-微信上传
				_licResId: [],
				licResId: "",
				name: "",
				creditCode: "",
				nature: "",
				coRegAddr: "",
				ticketAddr: "",
				postCode: "",
				establishment: "",
				licDeadlineType: "2", // 2-有限，1-永久
				licDeadlineStart: "",
				licDeadlineEnd: "",
				capital: "",
				businessScope: "",
				// 法人信息
				_bossFront: {
					fileList: [],
				},
				_bossBack: {
					fileList: [],
				},
				legalIdType: "1", // 1-大陆身份证，2-护照，3-港澳居民通行证，4-台湾居民通行证
				legalUploadType: "1", // 1-电脑上传，2-微信上传
				_idCardJustPath: [],
				idCardJustPath: "",
				_idCardBackPath: [],
				idCardBackPath: "",
				legalName: "",
				legalIdCode: "",
				idCardDeadlineType: "2", // 2-有限，1-永久
				idCardDeadlineStart: "",
				idCardDeadlineEnd: "",
				legalPhone: "",
				// 税务信息
				_tax: {
					fileList: [],
				},
				taxPayerFlag: "0", // 1-一般纳税人，2-小规模纳税人
				_taxPayerPic: [],
				taxPayerPic: "",
				// 银行信息
				bankDesc: "",
				bankDescCode: "",
				bankCode: "",
				bankAddr: "",
				bankCountMaster: "",
				selfOrAll: "", // 1-对公，2-对私
			},
			mustHasDefaultKey: [
				"licUploadType",
				"licDeadlineType",
				"legalIdType",
				"legalUploadType",
				"idCardDeadlineType",
				"taxPayerFlag",
			],
			companyInfoFormRules: {
				// 营业执照
				licUploadType: {
					required: true,
					message: "请选择上传类型",
					trigger: "change",
				},
				_licResId: {
					required: true,
					message: "请上传营业执照",
					trigger: "change",
				},
				name: [
					{
						required: true,
						message: "请输入公司名称",
						trigger: "blur",
					},
					{ max: 50, message: "最多输入50位字符", trigger: "change" },
				],
				creditCode: [
					{
						required: true,
						message: "请输入统一信用代码",
						trigger: "blur",
					},
					{ max: 18, message: "最多输入18位字符", trigger: "change" },
				],
				nature: [
					{
						required: true,
						message: "请选择公司类型",
						trigger: "blur",
					},
				],
				coRegAddr: [
					{
						required: true,
						message: "请输入营业执照所在地",
						trigger: "blur",
					},
				],
				ticketAddr: [
					{
						required: true,
						message: "请输入开票信息地址",
						trigger: "blur",
					},
				],
				postCode: [
					{
						required: true,
						message: "请输入邮政编码",
						trigger: "blur",
					},
				],
				establishment: [
					{
						required: true,
						message: "请选择成立日期",
						trigger: "blur",
					},
				],
				licDeadlineType: [
					{
						required: true,
						message: "请选择营业期限",
						trigger: "change",
					},
				],
				licDeadlineStart: [
					{
						required: true,
						message: "请选择营业期限开始时间",
						trigger: "blur",
					},
				],
				licDeadlineEnd: [
					{
						required: true,
						message: "请选择营业期限结束时间",
						trigger: "blur",
					},
				],
				capital: [
					{
						required: true,
						message: "请输入注册资本",
						trigger: "blur",
					},
				],
				businessScope: [
					{
						required: true,
						message: "请输入经营范围",
						trigger: "blur",
					},
				],
				// 法人信息
				legalIdType: [
					{
						required: true,
						message: "请选择证件类型",
						trigger: "change",
					},
				],
				legalUploadType: [
					{
						required: true,
						message: "请选择证件上传类型",
						trigger: "change",
					},
				],
				_idCardJustPath: [
					{
						required: true,
						message: "请上传证件人像面",
						trigger: "change",
					},
				],
				_idCardBackPath: [
					{
						required: true,
						message: "请上传证件国徽面",
						trigger: "change",
					},
				],
				legalName: [
					{
						required: true,
						message: "请输入法人姓名",
						trigger: "blur",
					},
					{
						pattern: /^[\u4e00-\u9fa5a-zA-Z]+$/,
						message: "仅支持中英文",
						trigger: "blur",
					},
				],
				legalIdCode: [
					{
						required: true,
						message: "请输入法人身份证号",
						trigger: "blur",
					},
					{ validator: identityCardsValidator, trigger: "blur" },
					{ max: 18, message: "最多输入18位字符", trigger: "change" },
				],
				idCardDeadlineType: [
					{
						required: true,
						message: "请选择法人证件有效期",
						trigger: "change",
					},
				],
				idCardDeadlineStart: [
					{
						required: true,
						message: "请选择证件有效期开始时间",
						trigger: "blur",
					},
				],
				idCardDeadlineEnd: [
					{
						required: true,
						message: "请选择证件有效期结束时间",
						trigger: "blur",
					},
				],
				legalPhone: [
					{
						required: true,
						message: "请输入法人手机号",
						trigger: "blur",
					},
					{ max: 11, message: "最多输入11个字符", trigger: "blur" },
					{
						pattern:
							/^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/,
						message: "请输入有效的手机号码",
						trigger: ["blur"],
					},
				],
				// 税务信息
				taxPayerFlag: [
					{
						required: true,
						message: "请选择纳税人类型",
						trigger: "change",
					},
				],
				_taxPayerPic: [
					{
						required: true,
						message: "请上传一般纳税人证件",
						trigger: "change",
					},
				],
				// 银行信息
				bankDesc: [
					{
						required: true,
						// message: "请输入支行名称",
						message: "请选择支行",
						trigger: "blur",
					},
				],
				bankCode: [
					{
						required: true,
						message: "请输入银行账号",
						trigger: "blur",
					},
					{
						pattern: /^([1-9]{1})(\d{0,19})$/,
						message: "请输入正确的银行账号",
						trigger: ["change", "blur"],
					},
				],
				bankAddr: [
					{
						required: true,
						message: "请输入开户行地址",
						trigger: "blur",
					},
				],
				bankCountMaster: [
					{
						required: true,
						message: "请输入银行账户持有人",
						trigger: "blur",
					},
				],
				selfOrAll: [
					{
						required: true,
						message: "请选择对公对私",
						trigger: "blur",
					},
				],
			},
			// 选择银行
			selectBankFlag: false,
			// 操作
			submitPendingFlag: false,
			// ocr定时器
			licenseTimer: null,
			bossFrontTimer: null,
			bossBackTimer: null,
		};
	},
	methods: {
		/**
		 * 恢复缓存信息
		 */
		recoveryCache(system = {}) {
			JoinFrom.set(system);
			let cache = JoinFrom.get();
			// 判断  && 更新缓存
			// if (cache.name) {
			let temp = {};
			for (let key in this.companyInfoForm) {
				if (!key.includes("_") && typeof cache[key] != "undefined") {
					if (this.mustHasDefaultKey.includes(key) && !cache[key]) {
						continue;
					} else {
						this.$set(temp, key, cache[key]);
					}
				}
			}
			Object.assign(this.companyInfoForm, temp);
			// 格式化中间变量
			this.formatMiddleVariable();
			// }
			// 二维码
			this.createQrCode("license");
			this.createQrCode("bossId");
		},
		async getJoinAllFormInfo() {
			try {
				let res = await getJoinAllFormInfo();
				if (res.data.code === 0) {
					if (res.data.data.submitStatus == 2) {
						this.$router.push({
							name: "StatusSubmitted",
						});
					}
					this.recoveryCache(res.data.data);
				} else {
					this.recoveryCache();
				}
			} catch (error) {
				this.recoveryCache();
			}
		},
		/**
		 * 兼容 - 返显
		 */
		formatMiddleVariable() {
			// uploader
			this.companyInfoFormPicKeyList.map(async (el) => {
				// java变量 ——> 中间变量
				try {
					// ⚠️ licResId 出现了 "null"
					this.companyInfoForm[`_${el.key}`] =
						this.companyInfoForm[el.key] === null ||
						this.companyInfoForm[el.key] === "null" ||
						this.companyInfoForm[el.key].length === 0
							? []
							: this.companyInfoForm[el.key].split(",");
				} catch (error) {
					console.warn(`[${el.key}] 解析异常`);
				}
				// 拼装fileList，展示图片
				let len = this.companyInfoForm[`_${el.key}`].length;
				if (len > 0) {
					for (let i = 0; i < len; i++) {
						let id = this.companyInfoForm[`_${el.key}`][i];
						try {
							let url = await getUploadFile(id);
							this.companyInfoForm[el.middleKey].fileList.push({
								url: url.data.data.res.url,
							});
						} catch (err) {
							console.warn("获取图片url失败");
						}
						// 设置 uploader 上传按钮点击状态
						let disabled =
							this.companyInfoForm[el.middleKey].fileList
								.length >= this.uploadConfig[el.middleKey].limit
								? true
								: false;
						this.$set(
							this.uploadConfig[el.middleKey],
							"disabled",
							disabled
						);
					}
				}
			});
		},
		/**
		 * 切换图片模块
		 */
		handleUploadTypeChange() {
			if (
				this.companyInfoForm.licUploadType == 1 ||
				this.companyInfoForm.legalUploadType == 1
			) {
				this.companyInfoFormPicKeyList.forEach((el) => {
					if (el["type"]) {
						if (
							this.companyInfoForm[`_${el.key}`].length == 0 &&
							this.companyInfoForm[el.key].length > 0
						) {
							this.companyInfoForm[`_${el.key}`].push(
								this.companyInfoForm[el.key]
							);
							this.$set(
								this.uploadConfig[el.middleKey],
								"disabled",
								true
							);
						}
					}
				});
			}
		},
		/**
		 * 生成二维码
		 */
		createQrCode(ref) {
			if (this[ref]) return;
			let userId = Number(sessionStorage.getItem("user_id"));
			let text = `${window.location.origin}/#/upload/${ref}?id=${userId}`;
			this[ref] = new QRCode(this.$refs[ref], {
				text,
				width: 110,
				height: 110,
				colorDark: "#000000",
				colorLight: "#ffffff",
				correctLevel: QRCode.CorrectLevel.H,
			});
			// 清除方法
			// this.$refs.qrcode.innerHTML = '';
			// qrcode.clear();
		},
		/**
		 * ocr - 提交素材
		 * 1-营业执照 2-身份证正面 3-身份证反面
		 */
		sumbitOcrSrc(_ref) {
			let ocrSrObj = {};
			this.companyInfoFormPicKeyList.some((el) => {
				if (el.middleKey === _ref) {
					ocrSrObj.type = el.type;
					ocrSrObj.resId =
						this.companyInfoForm[`_${el.key}`].join(",");
					return true;
				}
			});
			ocrSrObj.userId = Number(sessionStorage.getItem("user_id"));
			sumbitOcrSrc(ocrSrObj).then((res) => {
				if (res.data.code === 0) {
					this.startOcrTimer(_ref, ocrSrObj.type);
				}
			});
		},
		/**
		 * ocr - 定时器
		 */
		startOcrTimer(_ref, type) {
			let timerName = `${_ref.split("_")[1]}Timer`;
			this.handleOcrResult([type], timerName);
			this[timerName] = setInterval(() => {
				this.handleOcrResult([type], timerName);
			}, 1000);
		},
		async clearOcrTimer(timerName) {
			this.submitPendingFlag = false;
			// 更新ocr素材信息，解决如下问题
			// 1、切换上传类型，fileList未捕获到最新的证件，未展示最新证件
			// 2、微信上传，组件未同步最新证件
			try {
				let allInfo = await getJoinAllFormInfo();
				this.companyInfoFormPicKeyList.map(async (el) => {
					// 更新 ocr 解析之后的图片 fileList，展示图片
					if (el["type"]) {
						let id = allInfo.data.data[el.key];
						if (id !== this.companyInfoForm[`_${el.key}`][0]) {
							try {
								let url = await getUploadFile(id);
								this.companyInfoForm[`${el.key}`] = id;
								this.$set(
									this.companyInfoForm[el.middleKey].fileList,
									"0",
									{
										url: url.data.data.res.url,
									}
								);
							} catch (err) {
								console.warn("获取ocr素材url失败");
							}
						} else {
							console.warn(`${el.middleKey} ocr素材无更新`);
						}
					}
				});
			} catch (error) {
				console.warn("拉取ocr素材id失败");
			}
			// 清除ocr定时器
			if (!timerName) return;
			clearInterval(this[timerName]);
		},
		/**
		 * ocr - 识别结果
		 */
		handleOcrResult(typeArr, timerName) {
			typeArr.forEach((type) => {
				this.submitPendingFlag = true;
				getOcrResultByType(type)
					.then((res) => {
						if (res.data.code === 0) {
							// 1-解析中 2-解析成功 3-解析失败
							let ocrStatus = res.data.data.ocrStatus;
							if (ocrStatus == 2) {
								this.clearOcrTimer(timerName);
								Object.assign(
									this.companyInfoForm,
									res.data.data.data
								);
							} else if (ocrStatus == 3) {
								this.clearOcrTimer(timerName);
								console.warn("ocr解析失败");
							}
						} else {
							this.clearOcrTimer(timerName);
						}
					})
					.catch(() => {
						this.submitPendingFlag = false;
						this.clearOcrTimer(timerName);
					});
			});
		},
		/**
		 * uploader - 公共方法
		 * @param _ref [自定义参数，区分fileList]
		 * @param _key [值为服务端字段，参考接口文档]
		 */
		handleBeforeUpload(file, _ref) {
			let isRightFileType = this.uploadConfig.base.fileType.includes(
				file.type
			);
			if (!isRightFileType) {
				this.$message.warning(`文件格式错误`);
				return false;
			}
			let isRightSize =
				file.size / 1024 / 1024 < this.uploadConfig[_ref].maxSize;
			if (!isRightSize) {
				this.$message.warning(
					`文件不可超过${this.uploadConfig[_ref].maxSize}M`
				);
				return false;
			}
		},
		handleUploadSuccess(res, _ref, _key) {
			this.companyInfoForm[_key].push(res.data.res.id);
			this.$set(
				this.uploadConfig[_ref],
				"disabled",
				this.companyInfoForm[_key].length >=
					this.uploadConfig[_ref].limit
			);
			this.companyInfoFormPicKeyList.some((el) => {
				if (el.middleKey === _ref && el["type"]) {
					this.sumbitOcrSrc(_ref);
					return true;
				}
			});
		},
		handleFileRemove(file, _ref, _key) {
			let uploader = _ref.split("_")[1] + "Upload";
			let uploadFiles = [];
			this.$refs[uploader].uploadFiles.forEach((el) => {
				uploadFiles.push(el.url);
			});
			let removeIndex = uploadFiles.indexOf(file.url);
			if (removeIndex < 0) {
				return false;
			} else {
				this.companyInfoForm[_key].splice(removeIndex, 1);
				this.$set(this.uploadConfig[_ref], "disabled", false);
			}
		},
		handlePicturePreview(file) {
			this.uploadConfig.base.previewPictureSrc = file.url;
			this.uploadConfig.base.previewPictureFlag = true;
		},
		/**
		 * 选择银行
		 */
		toggleSelectBankFlag() {
			this.selectBankFlag = !this.selectBankFlag;
		},
		handleBankSelect(item) {
			if (item) {
				this.$set(this.companyInfoForm, "bankDesc", item.bankName);
				this.$set(this.companyInfoForm, "bankDescCode", item.bankCode);
			}
		},
		/**
		 * 重置
		 */
		handleReset() {
			// 表单
			this.companyInfoFormList.forEach((el) =>
				this.$refs[`companyInfoForm_${el}`].resetFields()
			);
			// uploader
			this.companyInfoFormPicKeyList.forEach((el) => {
				this.$refs[el.ref].clearFiles();
				this.$set(this.uploadConfig[el.middleKey], "disabled", false);
			});
		},
		/**
		 * 下一步
		 */
		formatSumbitParams() {
			let companyInfoForm = JSON.parse(
				JSON.stringify(this.companyInfoForm)
			);
			// 中间变量 -> java变量，且java变量仅发送数据使用
			this.companyInfoFormPicKeyList.forEach((el) => {
				this.$set(
					companyInfoForm,
					el.key,
					companyInfoForm[`_${el.key}`].join(",")
				);
			});
			// 删除中间变量
			for (let key in companyInfoForm) {
				if (key.includes("_")) {
					delete companyInfoForm[key];
				}
			}
			return companyInfoForm;
		},
		jump2nextStep() {
			// 税务信息 - 图片动态校验
			this.$set(
				this.companyInfoFormRules._taxPayerPic[0],
				"required",
				this.companyInfoForm.taxPayerFlag == 0
			);
			// 检验各个表单模块
			let isValidSuccess = this.companyInfoFormList.every((el) => {
				let result = true;
				this.$refs[`companyInfoForm_${el}`].validate((valid) => {
					result = !!valid;
				});
				return result;
			});
			if (isValidSuccess) {
				this.submitPendingFlag = true;
				let companyInfoForm = this.formatSumbitParams();
				JoinFrom.set({ ...this.companyInfoForm, ...companyInfoForm });
				submitCompanyInfoForm(companyInfoForm)
					.then((res) => {
						if (res.data.code === 0) {
							this.$router.push({ name: "StepBrandInfo" });
						} else {
							this.$message.warning(res.data.message);
						}
						this.submitPendingFlag = false;
					})
					.catch(() => {
						this.submitPendingFlag = false;
					});
			}
		},
	},
	created() {
		// 同步全量枚举
		this.$store.dispatch("getAllDictionaryList");
		// 获取全量信息
		this.getJoinAllFormInfo();
	},
	beforeDestroy() {
		this.companyInfoFormPicKeyList.forEach((el) => {
			let timerName = `${el.middleKey.split("_")[1]}Timer`;
			if (this[timerName]) {
				this.clearOcrTimer(timerName);
			}
		});
	},
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/base.scss";
.page-step-company-info {
	.card-inner {
		padding: 25px 40px 15px;
		margin: 6px 220px;
		box-shadow: 0px 10px 30px 0px rgba(201, 201, 201, 0.2);
	}
	.label {
		font-size: 20px;
		color: #4a4a4a;
		line-height: 26px;
		margin-bottom: 25px;
	}
	.custom-form-item {
		margin-left: -220px;
	}
	.qr-code-box {
		position: relative;
		width: 260px;
		padding: 12px 12px 0;
		border: 1px solid #e6e6e6;
		.qr-code {
			display: inline-block;
		}
		.text-box {
			position: absolute;
			top: 70px;
			left: 136px;
			line-height: 20px;
			font-size: 14px;
			color: #333333;
			margin-top: -60px;
		}
		.ocr-btn {
			width: 100px;
			height: 40px;
			line-height: 40px;
			text-align: center;
			padding: 0;
			color: $color-theme !important;
			font-size: 14px !important;
			font-weight: 400;
			margin-left: 16px;
			margin-top: 70px;
			border-color: $color-theme !important;
			background: #fff !important;
			vertical-align: top;
		}
	}
	.date-picker-limit {
		width: 126px;
	}
	.date-picker {
		width: 190px;
	}
	.date-range-box {
		display: inline-block;
		margin-top: -10px;
		.date-item {
			display: inline-block;
		}
	}
	.reg-capital {
		width: 130px;
	}
	.btn-select {
		width: 124px;
		height: 40px;
		font-size: 14px;
		font-weight: 400;
		color: $color-theme !important;
		border-color: $color-theme !important;
		background: none !important;
		border-radius: 3px;
	}
	.custom-card {
		position: relative;
		.editable {
			z-index: 100;
		}
		.mask {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 99;
		}
	}
}
</style>